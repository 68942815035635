import React from "react"
import { graphql } from "gatsby"

import PostListTemplate from './post_list_layout'

const PostCatsQuery = function ({ data: { allSitePage: { edges }} }) {
  return (
    <PostListTemplate list={edges[0].node.context.list} cat={edges[0].node.context.cat} />
  )
}

export const pageQuery = graphql`
query ($cat: String!) {
  allSitePage(filter: {context: {cat: {eq: $cat}}}) {
    edges {
      node {
        id
        context {
          list {
            slug
            title
            date
            excerpt
          }
          cat
        }
      }
    }
  }
}
`

export default PostCatsQuery
